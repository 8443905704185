import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Convert 2.0 Review – Frank Kern",
  "author": "tzookb",
  "type": "post",
  "date": "2019-01-12T20:18:54.000Z",
  "url": "/2019/01/convert-2-0-review-frank-kern/",
  "categories": ["Book Reviews"],
  "tags": ["books", "marketing"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` Quick nice book from Frank Kern. I was ready for laser focused self selling book. But it was far from that. Of course Frank showed me how skillful he is but share a lot of useful and practical information I can start working with. That is exactly what he is preaching in this book, give value, give some more value, possession yourself as an expert and sales will come. I would be more then happy to work with with him but for now he is above my pay grade 🙂 So for the book itself and what I got from it. Stop focusing on hard sales, product launches and keep running on the hamster wheel to get more clients and sales. Everybody are doing that and clients are mostly blind to those ads and “limited time” promotions. Unless you are really a marketing master or copywriter wiz. 4 items that help people decide buying from you: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`They know you`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They trust you`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They like you`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They need what you sell Focus on 3 major steps for your prospects flow to become clients: `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`pre-framing`}</p>
        <ul parentName="li">
          <li parentName="ul">{`give the great content that show you are an expert. Could be from content or other people pre-frame on you`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`indoctrination `}</p>
        <ul parentName="li">
          <li parentName="ul">{`bond with your user, after he got to enjoy your content. Share your story, you goals, your mission, make him like you.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`conversion`}</p>
        <ul parentName="li">
          <li parentName="ul">{`after they know you are an expert and they like you. Now you can share your product/s There are much more examples and case studies in the book but it’s to late and Im tired as hell 🙂 P.S. got some ideas after going through the book so I need to summarize them…`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      